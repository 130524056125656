import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Pagination from "../components/pagination"
import PageHeader from "../components/blogHeader"
import ListingHeader from "../components/listingHeader"
import { useAnimation, motion, AnimatePresence } from "framer-motion"
import { useInView } from "react-intersection-observer"
import "./work-listing.scss"

const sectionAnim = {
  initial: {
    opacity: 0,
    // y: 20,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      // when: "beforeChildren",
      staggerChildren: 0.15,
      staggerDirection: 1,
      duration: 0.6,
    },
  },
}

const sectionItem = {
  initial: {
    opacity: 0,
    y: 10,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      // when: "beforeChildren",
      // delayChildren: 0.4,
      staggerChildren: 0.15,
      duration: 0.6,
      // ease: [0.16, 1, 0.3, 1]
    },
  },
}

const animImage = {
  initial: {
    scale: 1.1,
  },
  visible: {
    scale: 1,
    transition: {
      // when: "beforeChildren",
      staggerChildren: 0.1,
      duration: 1,
      // ease: [0.16, 1, 0.3, 1]
    },
  },
}

const workListingTemplate = props => {
  const { pageContext, data } = props
  const { previousPagePath, nextPagePath, humanPageNumber, numberOfPages } =
    pageContext

  const settings = data.settings.nodes[0]

  console.log("pageContext", pageContext)
  console.log("data", data)

  return (
    <Layout pageSeo={settings} className="bg-primary">
      <ListingHeader data={settings} />

      <section className="-mt-16 z-10">
        <div className="container">
          {/* <div className="grid grid-cols-12">
            <h2 className="text-2xl font-serif italic col-span-4">Our Guarantee</h2>
            <h2 className="lead col-span-8">Happy collaborations are built on clarity, transparency and a shared vision. Whether it’s in our dealings or in our productions, we never lose sight of the human touch. It’s at the heart of every campaign we develop.</h2>
          </div> */}

          <motion.div
            variants={sectionAnim}
            className="news-listing__list grid grid-cols-1 lg:grid-cols-2 gap-4 p-4 rounded-md"
          >
            {data.posts.edges.map((post, index) => {
              const controls = useAnimation()
              const [ref, inView] = useInView({
                threshold: 0.5,
              })

              useEffect(() => {
                if (inView) {
                  controls.start("visible")
                }
              }, [controls, inView])

              return (
                <motion.article
                  variants={sectionAnim}
                  ref={ref}
                  animate={controls}
                  initial="initial"
                  className="news-listing__article relative shadow-lg shadow-primary/10 overflow-hidden rounded-lg z-10" 
                >
                  <Link to={post.node.slug.current}>
                    <motion.div variants={animImage} className=" z-10">
                      <GatsbyImage
                        // className="aspect-video rounded-tl-[12rem] rounded-br-[12rem]"
                        image={post.node.featuredImage.asset.gatsbyImageData}
                        alt={post.node.featuredImage.asset.altText}
                        className="aspect-w-1 aspect-h-1 lg:aspect-w-16 lg:aspect-h-9 w-full h-full object-cover"
                      />
                    </motion.div>
                  </Link>
                  <div className="absolute z-20 inset-0 flex items-center justify-center text-center">
                    <div>

                      <motion.h2
                        className="h2 uppercase mb-4"
                        variants={sectionItem}
                      >
                        {post.node.title}
                      </motion.h2>
                      <motion.a
                        href={post.node.slug.current}
                        className="font-semibold underline-anim"
                        variants={sectionItem}
                      >
                        GET THE DETAILS
                      </motion.a>
                    </div>
                  </div>
                </motion.article>
              )
            })}
          </motion.div>

          <Pagination pageContext={pageContext} />
        </div>
      </section>
    </Layout>
  )
}

export default workListingTemplate

export const query = graphql`
  query ($skip: Int!, $limit: Int!) {
    posts: allSanityWork(
      sort: { fields: _createdAt, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          _createdAt(formatString: "MMMM DD, YYYY")
          _id
          _key
          _type
          _updatedAt(formatString: "MMMM DD, YYYY")
          featuredImage {
            _type
            _key
            asset {
              altText
              gatsbyImageData
            }
          }
          id
          title
          slug {
            _key
            _type
            current
          }
        }
      }
    }
    settings: allSanityWorkSettings {
      nodes {
        _key
        _type
        heading
        description
        id
        image {
          _key
          _type
          asset {
            altText
            gatsbyImageData
          }
        }
        metaDesc
        metaTitle
        shareGraphic {
          asset {
            url
          }
        }
      }
    }
  }
`
